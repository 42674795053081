package hu.mkik.vb.portal.ui.administration.taskType

import hu.mkik.vb.portal.model.TaskType
import hu.mkik.vb.portal.model.runtime.TaskTypeRole
import hu.mkik.vb.portal.ui.proceeding.participation.components.transformedName
import hu.mkik.vb.portal.ui.secretaryOrTechnical
import hu.mkik.vb.portal.ui.strings
import hu.mkik.vb.portal.ui.taskTypeService
import hu.mkik.vb.portal.ui.taskTypes
import hu.simplexion.z2.browser.browserIcons
import hu.simplexion.z2.browser.css.*
import hu.simplexion.z2.browser.field.FieldState
import hu.simplexion.z2.browser.html.*
import hu.simplexion.z2.browser.immaterial.schematic.attach
import hu.simplexion.z2.browser.immaterial.schematic.field
import hu.simplexion.z2.browser.immaterial.schematic.switchField
import hu.simplexion.z2.browser.immaterial.schematic.touch
import hu.simplexion.z2.browser.immaterial.select.SelectBase
import hu.simplexion.z2.browser.immaterial.select.SelectConfig
import hu.simplexion.z2.browser.layout.scrolledBoxWithLabel
import hu.simplexion.z2.browser.layout.surfaceContainerLowest
import hu.simplexion.z2.browser.material.button.filledLaunchButton
import hu.simplexion.z2.browser.material.button.textButton
import hu.simplexion.z2.browser.material.snackbar.snackbar
import hu.simplexion.z2.browser.routing.NavRouter
import hu.simplexion.z2.localization.locales.localeCapitalized
import hu.simplexion.z2.util.UUID
import hu.simplexion.z2.util.localLaunch
import mainRouter

object EditTaskType : NavRouter(useParentNav = true, visibility = secretaryOrTechnical) {
    val uuid by uuid<TaskType>()
    override var default: Z2Builder = { edit(uuid) }
}

object AddTaskType : NavRouter(useParentNav = true, visibility = secretaryOrTechnical) {
    override var default: Z2Builder = { edit(UUID.nil()) }
}

internal fun Z2.edit(uuid: UUID<TaskType>) {
    localLaunch {
        val add = (uuid == UUID.nil<TaskType>())
        val noChange = !add
        val taskType = if (add) TaskType() else taskTypeService.get(uuid)
        val roles = taskTypeService.roles()

        lateinit var role : SelectBase<TaskTypeRole>

        surfaceContainerLowest(borderOutline, p24) {
            div(w400) {
                div(titleLarge, pb24) {
                    text { if (add) strings.addTaskType.localeCapitalized else strings.editTaskType.localeCapitalized }
                    if (! add) {
                        div(pt4, bodySmall, onSurfaceVariantText) { text { taskType.uuid } }
                    }
                }

                div(positionRelative, mb24) {
                    scrolledBoxWithLabel(strings.proceedingType) {
                        field { taskType.proceedingType } readOnly noChange
                    }
                }

                field { taskType.name } readOnly noChange

                div {
                    attach(taskType) {
                        role = role(taskType, roles)
                    }
                }
                field { taskType.deadlineDays }
                field { taskType.note }

                div(displayFlex, alignItemsCenter, justifyContentSpaceBetween) {
                    div(labelLarge) { + strings.active }
                    switchField { taskType.active }
                }

                grid(pt24, gridGap16) {
                    gridTemplateColumns = "1fr min-content"

                    textButton(strings.cancel) { mainRouter.back() } css justifySelfEnd

                    filledLaunchButton(strings.save) {
                        taskType.touch()

                        var valid = if (add) taskType.isValidForCreate else taskType.isValid

                        if (role.valueOrNull == null) {
                            valid = false
                            role.toError(strings.mandatorySelect.localized)
                        }

                        if (! valid) {
                            snackbar(strings.invalidFields)
                            return@filledLaunchButton
                        }

                        if (add) {
                            taskTypeService.add(taskType)

                            snackbar(strings.createSuccess)

                            taskTypes.clear()
                            taskTypes += taskTypeService.list().associateBy { it.uuid }

                            mainRouter.back()
                        } else {
                            taskTypeService.update(taskType)

                            taskTypes.clear()
                            taskTypes += taskTypeService.list().associateBy { it.uuid }

                            snackbar(strings.saveSuccess)
                            mainRouter.back()
                        }
                    }
                }
            }
        }
    }
}

private fun Z2.role(taskType: TaskType, roles: List<TaskTypeRole>): SelectBase<TaskTypeRole> {
    return SelectBase(
        this,
        FieldState().also {
            it.label = strings.role.localized
        },
        SelectConfig<TaskTypeRole>().apply {
            itemTextFun = { it.role.transformedName }
            trailingIcon = browserIcons.down
            options = roles.filter { it.proceedingType == taskType.proceedingType }
            onChange = {
                taskType.assignToRole = it.value.role
            }
        }
    ).main().apply {
        valueOrNull = if (taskType.assignToRole.isNil) {
            null
        } else {
            roles.firstOrNull { it.proceedingType == taskType.proceedingType && it.role == taskType.assignToRole }
        }
    }
}