package hu.mkik.vb.portal.model.finance

import hu.simplexion.z2.schematic.Schematic

class BankStatement : Schematic<BankStatement>() {

    var uuid by uuid<BankStatement>(true)

    var statementId by string()
    var accountNumber by string()

    var startDate by localDate()
    var endDate by localDate()

    var creditCount by int()
    var debitCount by int()

    var creditAmount by decimal(scale = 0)
    var debitAmount by decimal(scale = 0)

    var remainingCredit by decimal(scale = 0)
    var remainingDebit by decimal(scale = 0)

}