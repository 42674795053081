package hu.mkik.vb.portal.ui.task.pages

import hu.mkik.vb.portal.ui.bank.pages.TransactionFilter
import hu.mkik.vb.portal.ui.component.singleSelect
import hu.mkik.vb.portal.ui.strings
import hu.mkik.vb.portal.ui.util.customChip
import hu.simplexion.z2.browser.css.alignSelfCenter
import hu.simplexion.z2.browser.css.gridAutoFlowColumn
import hu.simplexion.z2.browser.css.pb12
import hu.simplexion.z2.browser.css.pl8
import hu.simplexion.z2.browser.css.titleLarge
import hu.simplexion.z2.browser.css.whiteSpaceNoWrap
import hu.simplexion.z2.browser.html.div
import hu.simplexion.z2.browser.html.grid
import hu.simplexion.z2.browser.immaterial.table.builders.TableBuilder
import hu.simplexion.z2.util.hereAndNow

val years = (2023..hereAndNow().year).toList().reversed()

fun TableBuilder<*>.transactionHeader(title: String, filter: TransactionFilter) =
    header {
        title {
            grid("repeat(auto-fit, minmax(min-content, 220px))", "max-content", gridAutoFlowColumn) {

                div(alignSelfCenter, titleLarge, whiteSpaceNoWrap, pb12, pl8, *textClasses) {
                    text { title }
                }

                singleSelect(years, hereAndNow().year, label = strings.filterByDate) {
                    filter.year = it.valueOrNull
                }.customChip()
            }
        }
        search()
    }