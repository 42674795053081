package hu.mkik.vb.portal.ui.component

import hu.simplexion.z2.browser.browserIcons
import hu.simplexion.z2.browser.field.FieldState
import hu.simplexion.z2.browser.field.FieldStyle
import hu.simplexion.z2.browser.html.Z2
import hu.simplexion.z2.browser.immaterial.select.SelectBase
import hu.simplexion.z2.browser.immaterial.select.SelectConfig
import hu.simplexion.z2.browser.material.textfield.AbstractField
import hu.simplexion.z2.localization.text.LocalizedText


fun <T> Z2.singleSelect(
    options: List<T>,
    value: T? = null,
    label: LocalizedText? = null,
    supportingText: LocalizedText? = null,
    itemTextFun: ((value: T) -> String)? = null,
    onChange: ((field: AbstractField<T>) -> Unit)? = null
) =
    SelectBase(
        this,
        FieldState().also {
            if (label != null) it.label = label.toString()
            if (supportingText != null) it.supportText = supportingText.toString()
        },
        SelectConfig(
            style = FieldStyle.Chip,
            options = options,
            onSelectedFun = onChange
        ).also {
            itemTextFun?.let { f -> it.itemTextFun = f }
            it.trailingIcon = browserIcons.down
            //it.singleChipSelect = true
        }
    ).main().also {
        if (value != null) it.value = value
    }