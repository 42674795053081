package hu.mkik.vb.portal.ui.proceeding.task.util

import hu.mkik.vb.portal.model.Participation
import hu.mkik.vb.portal.model.Task
import hu.mkik.vb.portal.model.TaskType
import hu.mkik.vb.portal.ui.proceeding.currentParticipations
import hu.mkik.vb.portal.ui.proceeding.currentProceeding
import hu.mkik.vb.portal.ui.proceeding.get
import hu.mkik.vb.portal.ui.proceeding.participation.components.transformedName
import hu.mkik.vb.portal.ui.strings
import hu.mkik.vb.portal.ui.taskTypes
import hu.mkik.vb.portal.ui.util.filterByRole
import hu.mkik.vb.portal.ui.util.plusDays
import hu.simplexion.z2.auth.model.Role
import hu.simplexion.z2.browser.browserIcons
import hu.simplexion.z2.browser.css.bodySmall
import hu.simplexion.z2.browser.css.onSurfaceVariantText
import hu.simplexion.z2.browser.field.FieldState
import hu.simplexion.z2.browser.html.Z2
import hu.simplexion.z2.browser.html.div
import hu.simplexion.z2.browser.immaterial.select.SelectBase
import hu.simplexion.z2.browser.immaterial.select.SelectConfig
import hu.simplexion.z2.util.UUID
import hu.simplexion.z2.util.hereAndNow

fun Z2.type(task: Task, role: UUID<Role>): SelectBase<TaskType> {
    return SelectBase(
        this,
        FieldState().also {
            it.label = strings.taskType.localized
        },
        SelectConfig<TaskType>().apply {
            itemTextFun = { it.name } // FIXME localized role name
            trailingIcon = browserIcons.down
            options = taskTypesFor(role)
            onChange = {
                task.type = it.value.uuid
                task.note = it.value.note
                task.deadline = hereAndNow().plusDays(it.value.deadlineDays).date
                it.state.error = false
            }
        }
    ).main().apply {
        valueOrNull = taskTypes[task.type]
    }
}

fun taskTypesFor(role: UUID<Role>) =
    taskTypes.values.filter {
        it.proceedingType == currentProceeding.proceeding.type
            && it.active
            && it.assignToRole == role
    }


fun Z2.assignee(task: Task, role: UUID<Role>): SelectBase<Participation> {
    return SelectBase(
        this,
        FieldState().also {
            it.label = strings.assignedTo.localized
        },
        SelectConfig<Participation>().apply {
            itemTextFun = { it.fullName }
            itemContentFun =  {
                div { + it.fullName }
                div(bodySmall, onSurfaceVariantText) { +it.proceedingRole.transformedName }
            }
            trailingIcon = browserIcons.down
            options = currentParticipations.filterByRole(role)
            onChange = {
                task.assignedTo = it.value.uuid
                it.state.error = false
            }
        }
    ).main().apply {
        valueOrNull = task.assignedTo[currentParticipations]
    }
}