package hu.mkik.vb.portal.model

import hu.simplexion.z2.auth.model.Role
import hu.simplexion.z2.schematic.Schematic

class ProceedingRole() : Schematic<ProceedingRole>() {
    var uuid by uuid<Role>()
    var name by string()
    var displayOrder by int()
    var group by boolean()
}