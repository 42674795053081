package hu.mkik.vb.portal.ui.proceeding.participation.components

import hu.mkik.vb.portal.model.Participation
import hu.mkik.vb.portal.model.ProceedingRole
import hu.mkik.vb.portal.ui.isSecretary
import hu.mkik.vb.portal.ui.proceeding.currentProceeding
import hu.mkik.vb.portal.ui.proceeding.proceedingRouter
import hu.mkik.vb.portal.ui.strings
import hu.mkik.vb.portal.ui.util.localized
import hu.simplexion.z2.auth.model.Role
import hu.simplexion.z2.browser.css.cursorPointer
import hu.simplexion.z2.browser.css.positionRelative
import hu.simplexion.z2.browser.css.wMinContent
import hu.simplexion.z2.browser.css.whiteSpaceNoWrap
import hu.simplexion.z2.browser.html.Z2
import hu.simplexion.z2.browser.html.div
import hu.simplexion.z2.browser.html.onClick
import hu.simplexion.z2.deprecated.browser.CssClass
import hu.simplexion.z2.util.UUID

fun Z2.participant(participation: Participation?, vararg classes: CssClass) {
    div(*classes, wMinContent, whiteSpaceNoWrap, positionRelative) {
        +participation?.fullName
        if (isSecretary && participation != null) {
            addCss(cursorPointer)
            onClick {
                proceedingRouter.participants.open()
            }
        }
    }
}

fun Z2.participants(participations: List<Participation>, vararg classes: CssClass) {
    div(*classes, wMinContent, whiteSpaceNoWrap, positionRelative) {
        for (participant in participations) {
            participant(participant)
        }
    }
}

val ProceedingRole.transformedName: String
    get() =
        if (
            currentProceeding.proceeding.soleArbitrator &&
            (currentProceeding.settings.chairmanRole == this.uuid || currentProceeding.settings.councilRole == this.uuid)
        ) {
            strings.soleArbitratorRoleName.localized
        } else {
            name
        }

val UUID<Role>.transformedName: String
    get() = currentProceeding.allRoles.firstOrNull { it.uuid == this }?.transformedName ?: this.localized

fun isRoleValidForSoleArbitrator(copy: Participation): Boolean {

    val settings = currentProceeding.settings

    if (currentProceeding.proceeding.soleArbitrator) {
        if (copy.proceedingRole == settings.claimantArbitratorRole || copy.proceedingRole == settings.respondentArbitratorRole) {
            return false
        }
    }

    return true

}