package hu.mkik.vb.portal.ui.bank.pages

import hu.mkik.vb.portal.model.finance.BankTransaction
import hu.simplexion.z2.browser.immaterial.table.Table
import hu.simplexion.z2.schematic.Schematic

class TransactionFilter : Schematic<TransactionFilter>() {

    var year by int().nullable()

    fun filter(table: Table<BankTransaction>, item: BankTransaction): Boolean {
        if (year != null && item.valueDate.year != year) return false

        val text = table.searchText?.lowercase() ?: return true

        table.columns.forEach {
            if (it.matches(item, text)) return true
        }

        return false
    }

}