package hu.mkik.vb.portal.ui

import hu.simplexion.z2.IBaseStrings
import hu.simplexion.z2.localization.text.ICommonStrings
import hu.simplexion.z2.localization.text.IDateTimeStrings
import hu.simplexion.z2.schematic.schema.validation.IValidationStrings

internal object strings : ICommonStrings, IDateTimeStrings, IBaseStrings, IValidationStrings {

    val documentUploadSubject = static("VB Portál - új dokumentum a {caseNumber} ügyben")
    val documentUploadContent =
        static(
            """
                <p>Tisztelt {participantName}!</p>
                <p>A {caseNumber} ügyben új dokumentum került feltöltésre:</p>
                <p style="padding-left: 24px">{documentName}</p>
                <p>Üdvözlettel,</p>
                <p>Választottbíróság</p>
            """.trimIndent()
        )
    val documentPermissions = static("Dokumentum jogosultságok")
    val passwordStrength = static("jelszó minimális erőssége")
    val removeProceedingPairingFirst = static("Ehhez a tételhez van ügy előírás párosítva. Először az ügy előírás párosítást kell bontani az ügy Befizetések vagy Kifizetések oldalán.")
    val select = static("kiválaszás")
    val folderFilterWithGridView = static("Ikon nézetben célszerű kikapcsolni a mappára való szűrést.")
    val dmsOneFilingNumber = static("DMSOne iktatószám")
    val dmsOneSubmissionNumber = static("DMSOne érkeztetési szám")

    val incomingFiling = static("bejövő iktatás")
    val outgoingFiling = static("kimenő iktatás")
    val szocho = static("SZOCHO")
    val honorarium = static("honorárium")
    val calculationExplanation = static("A kalkuláció kötötten 3 bíróra számítja a díjakat a perérték változás alapján.")
    val missingFees = static("A kalkuláció nem lehetséges, mivel nem szerepel az ügyhöz érvényes díjtábla a rendszerben.")
    val requestPasswordReset = static("új jelszó kérése")
    val passwordResetError = static("Nem szerepel ilyen email cím illetve fióknév a rendszerben. Kérem ellenőrizze!")
    val tooManyAttempts = static("Túl sok próbálkozás, kérem próbálja később.")
    val passwordResetEmailSubject = static("VB-Portál - jelszó megújítás")
    val passwordResetEmailContent =
        static(
            """
                <p>Tisztelt {name}!</p>
                <p>A VB Portál belépési jelszó megújításához kattintson az alábbi hivatkozásra:</p>
                <p><a href="{link}">{link}</a></p>
                <p>Üdvözlettel,</p>
                <p>Választottbíróság</p>
            """.trimIndent()
        )

    val twoFactorAuthentication = static("két faktoros azonosítás")
    val sessionActivationInterval = static("biztonsági kód érvényesség (perc)")
    val sessionExpirationInterval = static("munkamenet érvényesség (perc)")
    val invalidSecurityCode = static("érvénytelen biztonsági kód")

    //    val session = static("munkamenet")
    val securityCode = static("biztonsági kód")
    val securityCodeEmailSubject = static("VB-Portál - biztonsági kód")
    val securityCodeEmailContent =
        static(
            """
                <p>Tisztelt {name}!</p>
                <p>A VB Portál belépéshez szükséges biztonsági kód: {code}</p>
                <p>A kód az alábbi időpontig érvényes: {expiration}</p>
                <p>Üdvözlettel,</p>
                <p>Választottbíróság</p>
            """.trimIndent()
        )

    val currentPassword = static("jelenlegi jelszó")
    val successfulPasswordChange = static("sikeres jelszó változtatás")
    val successfulPasswordChangeExplanation = static("A tovább gomb megnyomása után megnyílik a belépés oldal, ahol az imént megadott jelszóval lehet belépni.")

    val successfulPasswordResetInit = static("sikeres jelszó megújítási kérés")
    val successfulPasswordResetInitExplanation = static("A rendszer hamarosan küld egy e-mailt a megadott címre, amely tartalmazza az új jelszó megadásához szükséges hivatkozást.")

    val mandatory = static("kötelező megadni")
    val Planned = static("tervezett")
    val Organized = static("kiírt")
    val Completed = static("lezajlott")
    val Cancelled = static("visszavont")

    val removeChosenCloseType = static("lezárási mód alaphelyzetre állítása")
    val MissingArbitrationCostPayment = static("elmaradt az előírt választottbírósági díj befizetése")
    val MissingCounterCostPayment = static("elmaradt az előírt választottbírósági díj befizetése")
    val WithdrawalBeforeEstablishment = static("a választottbírósági tanács megalakulása előtt a peres fél visszavonta a keresetet")
    val NoJudgementAfterEstablishment = static("a választottbírósági tanács megalakult, de nem történt ítélethozatal")
    val Judgement = static("ítélethozatal történt")
    val LateJudgement = static("ítélethozatal történt, de nem határidőre")
    val InvalidatedJudgement = static("érvénytelenített ítélet")

    val closeType = static("Lezárás módja")
    val archived = static("archivált")
    val established = static("választottbíróság megalakult")
    val adjourned = static("eljárás berekesztve")
    val partialJudgement = static("részítélet")
    val closed = static("eljárás lezárva")
    val reopened = static("eljárás visszanyitása az ítélet érvénytelenítése miatt")

    val modifyOnIncomingPage = static("a befizetések oldalon módosítható")
    val language = static("nyelv")
    val setoffGreaterThanClaim = static("nagyobb mint a kereseti perérték")
    val setoffGreaterThanCounterClaim = static("nagyobb mint a viszontkereseti perérték")
    val setoffObjection = static("beszámítási kifogás")

    val removeHearingConfirm = static("Valóban el szeretné távolítani a tárgyalást?")

    val date = static("dátum")
    val noHearing = static("Az ügyhöz nincs tárgyalás kitűzve.")
    val other = static("egyéb")
    val templates = static("Sablonok")
    val proceedingIsActive = static("Az ügy aktív.")
    val proceedingIsArchived = static("Az ügy archivált.")

    val archivedProceedings = static("Archivált ügyek")

    val archiveProceeding = static("Archíválás")
    val unarchiveProceeding = static("Aktiválás")

    val reports = static("Riportok")
    val proceedingRegisterReport = static("Nyilvántartás")
    val monthlySummaryReport = static("havi összesítő")
    val yearlySummaryReport = static("éves összesítő")
    val refundSummaryReport = static("visszautalások")
    val levySummaryReport = static("illetékek")
    val honorariumSummaryReport = static("tiszteletdíjak")

    val year = static("év")
    val month = static("hónap")
    val initialReserveFund = static("tartalékalap áthozat")

    val incomingRequirement = static("befizetési előírás")
    val outgoingRequirement = static("kifizetési előírás")

    val closedTransactions = static("lezárt tranzakciók")
    val pendingTransactions = static("függő tranzakciók")
    val successfulUpload = static("sikeres feltöltés")
    val bank = static("bank")

    val statementId = static("kivonatszám")
    val startDate = static("kezdőnap")
    val endDate = static("zárónap")
    val creditCount = static("jóváírások száma")
    val debitCount = static("terhelések száma")
    val creditAmount = static("jóváírások összege")
    val debitAmount = static("terhelések összege")
    val remainingCredit = static("függő jóváírások")
    val remainingDebit = static("függő terhelések")

    val statements = static("kivonatok")
    val successfulInvite = static("sikeres meghívás")
    val contractDate = static("szerdődés kelte")
    val closeDate = static("befejezés kelte")
    val accountingDate = static("számfejtés leadása")

    val closing = static("Zárás")
    val dates = static("Dátumok")

    val counterClaim = static("viszontkereset")
    val counterClaimAbrv = static("vk.")
    val notPaidYet = static("még nem kifizetett")
    val addProposedItems = static("javasolt tételek hozzáadása")
    val proposed = static("javasolt")
    val claimPayments = static("kereseti kifizetések")
    val counterClaimPayments = static("viszontkereseti kifizetések")

    val dmsonePartner = static("DMSOne azonosító")
    val representedParty = static("képviselt részvevők")
    val sameRankParticipantExists = static("ehhez a szerepkörhöz már van ilyen rangú részetvevő")
    val sameMpaidParticipantExists = static("ez a partner már fel van véve szereplőként")

    val bankAccount = static("bankszámla")
    val retired = static("nyugdíjas")
    val strength = static("erősség")

    val Poor = static("nagyon gyenge")
    val Weak = static("gyenge")
    val Reasonable = static("közepes")
    val Excellent = static("kiváló")

    val passwordRules = static("a jelszó összetettségére vonatkozó szabályok")
    val notSameAsBeforeN = static("nem lehet azonos az előző %N jelszóval")
    val minimumCharacter = static("legalább %N karakter")
    val minimumUppercase = static("legalább %N nagybetű")
    val minimumDigit = static("legalább %N szám")
    val specialCharacterMinimum = static("legalább %N speciális karakter")
    val sameCharacterMaximum = static("adott karakter maximális előfordulási száma %N")
    val entropyMinimum = static("jelszó minimális erőssége: %N")

    val nextPassword = static("új jelszó")
    val nextPasswordVerification = static("új jelszó még egyszer")

    val passwordChange = static("jelszó változtatás")
    val lastAuthSuccess = static("utolsó sikeres azonosítás")
    val authSuccessCount = static("sikeres azonosítások száma")
    val lastAuthFail = static("utolsó sikertelen azonosítás")
    val authFailCount = static("sikertelen azonosítások száma")

    val rank = static("rend")
    val secretary = static("ügyintéző")
    val Participant = static("résztvevő")
    val Secretariat = static("titkár")
    val Presidency = static("elnök")
    val Stenographer = static("jegyzőkönyv-vezető")
    //val Technical = static("rendszergazda")

    val loginInfo = static("belépési információk")
    val lastLogin = static("utolsó belépés")

    val activated = static("aktivált")
    val locked = static("zárolt")
    val anonymized = static("anonimizált")

    val activeRelease = static("aktív szoftver kiadás")
    val softwareUpdate = static("szoftver verzió")
    val administrationFee = static("adminisztrációs díj")
    val arbitratorHonorarium = static("választottbírói tiszteletdíj (egy bíró)")
    val arbitratorsHonorarium = static("választottbírói tiszteletdíj")
    val chairmanHonorarium = static("elnöki tiszteletdíj")
    val reserveFund = static("tartalék alap")
    val levy = static("illeték")
    val socialSecurityContribution = static("szociális hozzájárulás")
    val chairmanSocialSecurityContribution = static("elnöki szociális hozzájárulás")
    val total = static("összesen")

    val calculation = static("kalkuláció")
    val noClaimValue = static("viszontkereseti perértéket csak akkor lehet rögzíteni, ha van perérték")
    val addOutgoingPayment = static("kifizetés rögzítése")
    val outgoingPayments = static("megtörtént kifizetések")
    val outgoingRequirements = static("tervezett kifizetések")
    val negativeRequiredAmount = static("bontani kell a párosításokat a változtatás előtt")
    val alreadyPaidAmount = static("már befizetett összeg")
    val alreadyPaidAmountOut = static("már kifizetett összeg")
    val cannotWithdrawPaired = static("visszavonás előtt bontani kell ennek az előírásnak a párosításait")
    val withdrawnRequirement = static("visszavont előírás")
    val withdrawRequirement = static("előírás visszavonása")
    val feeCalculationError = static("díj számítási hiba, kérlek keresd a szoftver támogatást")
    val expedited = static("gyorsított eljárás")
    val numberOfArbitrators = static("bírók száma")
    val isRespondentLevyFree = static("alperes illetékmentes")
    val isClaimantLevyFree = static("felperes illetékmentes")
    val waiveCounterRegistrationFee = static("viszontkereseti regisztrációs díj elhagyása")
    val isChairmanRetired = static("az egyesbíró vagy az elnök nyugdíjas")
    val numberOfRetiredJudges = static("nyugdíjas bírók száma", "az elnök NE legyen benne")
    val amountChange = static("változás")
    val new = static("új")
    val alreadyAddedItems = static("korábban felvett")
    val amount = static("összeg")
    val RegistrationFee = static("regisztrációs díj")
    val ArbitrationFee = static("választottbírósági díj")
    val ExpenseAdvance = static("költség előleg")
    val incomingFinance = static("befizetések")
    val outgoingFinance = static("kifizetések")
    val confirmBreakPair = static("Biztosan bontani szeretnéd a párosítást?")
    val breakPair = static("bontás")
    val oneItemMayBeSelected = static("egyszerre egy-egy tételt lehet párosítani a két oldalról")
    val noPaymentSelected = static("nincs befizetés kiválasztva")
    val noRequirementSelected = static("nincs díj / költség kiválasztva")
    val paidIncomingRequirements = static("befizetett díjak és költségek")
    val paidOutgoingRequirements = static("kifizetett díjak és költségek")
    val addIncomingPayment = static("befizetés rögzítése")
    val valueDate = static("értéknap")
    val missingAmount = static("még hiányzó összeg")
    val remainingAmount = static("fennmaradó összeg")
    val requiredAmount = static("előírt összeg")
    val pairedAmount = static("párosított")
    val paymentAmount = static("összeg")
    val incomingPayments = static("befizetések")
    val addItem = static("tétel rögzítése")
    val incomingRequirements = static("várt díjak és költségek")
    val withdraw = static("visszavon")
    val loading = static("betöltés")
    val pairing = static("párosít")
    val finance = static("pénzügy")
    val info = static("adatok")
    val inProgress = static("folyamatban")
    val all = static("összes")
    val filterByStatus = static("szűrés állapot szerint")
    val filterByAssignee = static("szűrés felelős szerint")
    val ownOnly = static("csak saját")

    val taskCreatedEmailSubject = static("VB Portál - új feladat a {caseNumber} ügyben")
    val taskCreatedEmailContent =
        static(
            """
                <p>Tisztelt {name}!</p>
                <p>Új feladatot vettek fel az Ön számára a VB portálon.</p>
                <p>Feladat típus: {taskType}<br>Felelős: {assignedTo}<br>
                Határidő: {deadline}<br>
                Megjegyzések: {note}
                <p>Üdvözlettel,</p>
                <p>Választottbíróság</p>
            """.trimIndent()
        )

    val taskChangedEmailSubject = static("VB Portál - feladat változás a {caseNumber} ügyben")
    val taskChangedEmailContent =
        static("<p>Tisztelt {name}!</p><p>Feladat változás történt a VB portálon.</p><p>Feladat típus: {taskType}<br>Felelős: {assignedTo}<br>Határidő: {deadline}<br>Megjegyzések: {note}<p>Üdvözlettel,</p><p>Választottbíróság</p>")

    val taskDoneEmailSubject = static("VB Portál - feladat kész a {caseNumber} ügyben")
    val taskDoneEmailContent =
        static("<p>Tisztelt {name}!</p><p>Az alábbi feladatot készre állították a VB portálon.</p><p>Feladat típus: {taskType}<br>Felelős: {assignedTo}<br>Határidő: {deadline}<br>Megjegyzések: {note}<p>Üdvözlettel,</p><p>Választottbíróság</p>")

    val selectRoleFirst = static("szerepkört kell választani")
    val mandatorySelect = static("ki kell választani")
    val deadlineDays = static("határidő (nap)")
    val modifiedBy = static("módosította")
    val modifiedAt = static("módosítva")

    val startingUpload = static("Feltöltés indítása.")
    val invite = static("Meghívás")
    var welcomeTitle = static("Sikeres fiók aktiválás!")
    var welcomeContent = static("Üdvözöljük az MKIK mellett működő Állandó Választottbíróság portálján!")

    var accountActivation = static("Fiók aktiválás")
    var passwordMismatch = static("nem egyeznek a megadott jelszavak")

    var newAccountEmailSubject = static("VB Portál fiók")
    var newAccountEmailContent =
        static(
            """
            <p>Tisztelt {name}!</p>
            
            <p>Elkészült VB portál fiókja, melynek neve: {mpaid}</p> 
            <p>A fiókot aktiválni kell, amit erre a hivatkozásra kattintva tehet meg: <a href="{link}">{link}</a></p>
            
            <p>Üdvözlettel,</p>
            <p>Választottbíróság</p>
            """.trimIndent()
        )

    var proceedingInvitationSubject = static("{casenumber} meghívó")
    var proceedingInvitationContent = static("<p>Tisztelt {name}!</p><p>Meghívó {casenumber} ügyben való részvételre {role} szerepkörben.</p><p>Üdvözlettel,</p><p>Választottbíróság</p>")

    var hrsz = static("HRSZ")
    val filing = static("iktatás")
    val submissionMode = static("beküldés módja")
    val submitter = static("beküldő")
    val dataCarrier = static("adathordozó")
    val incoming = static("bejövő")
    val outgoing = static("kimenő")

    val filterByFolder = static("szűrés mappa szerint")
    val filterByType = static("szűrés típus szerint")
    val filterBySender = static("szűrés beküldő szerint")
    val filterByDate = static("szűrés dátum szerint")
    val filterByName = static("szűrés név szerint")

    val loginSupport = static("VB Portál")
    val forgottenPassword = static("Elfelejtett jelszó")
    val help = static("Segítség")
    val privacy = static("Adatvédelem")
    val term = static("Szabályok")
    val english = static("English")
    val registration = static("Regisztráció")

    val basicInfo = static("Alapvető információk")
    val additionalInfo = static("Kiegészítő információk")
    val birthData = static("Születési adatok")
    val addresses = static("Címek")
    val companyData = static("Cégadatok")
    val notes = static("Megjegyzések")

    val accountCreated = static("VB Portál fiók")

    val createSuccess = static("Sikeres létrehozás.")
    val allTasks = static("Összes feladat")
    val allEvents = static("Összes esemény")

    val invalidFields = static("Vannak még nem megfelelően kitöltött mezők.")

    val noDocuments = static("Ebben a mappában még nincsenek dokumentumok.")

    val mainFolder = static("Főmappa")
    val referenceNumber = static("Iktatószám")
    val submissionNumber = static("Érkeztetési szám")
    val claimant = static("Felperes")
    val respondent = static("Alperes")
    val filename = static("fájlnév")

    val activate = static("Aktiválás")
    val deactivate = static("Deaktiválás")
    val representedBy = static("Képviseli")
    val primary = static("Elsődleges")

    val noAccount = static("Ezzel az e-mail címmel még nem létezik fiók.")
    val size = static("Méret")
    val done = static("kész")
    val meghivas = static("Fiók létrehozása és meghívás")

    override val role = static("Szerepkör")
    val mpaid = static("MPAID")
    val roleName = static("Szerepkör")
    val participantName = static("Név")
    val active = static("Aktív")
    val uploader = static("Feltöltő")
    val uploadTime = static("Feltöltés időpontja")
    val participantSearchHint = static("név, legalább 3 karakter")
    val selectParticipant = static("résztvevőt kell először választani")
    val accountWithMpaidExists = static("Már regisztrált MPAID.")

    val keresetLevel = static("keresetlevél")
    val emailAddressWithAccount = static("Ehhez az e-mail címhez létezik fiók.")

    val tableView = static("táblázat nézet")
    val gridView = static("ikon nézet")

    val today = static("ma")
    val last7days = static("az elmúlt 7 napban")
    val last30days = static("az elmúlt 30 napban")

    val mainInfo = static("Főbb adatok")
    val claimValue = static("perérték")
    val counterClaimValue = static("viszontkereseti perérték")
    val letterOfClaimantDate = static("beérkezett")
    val description = static("leírás")

    val proceedingType = static("ügytípus")
    val documentSearchHint = static("keresés")
    val filter = static("Szűrés")
    val fullName = static("név")
    val email = static("e-mail")
    val phone = static("telefonszám")
    val nationality = static("állampolgárság")
    val profession = static("foglalkozás")
    val qualification = static("képzettség")
    val languageSkills = static("nyelvtudás")
    val section = static("tagozat")
    val scientificDegree = static("tudományos fokozat")
    val workplace = static("munkahely")
    val officePhone = static("munkahelyi telefon")
    val fax = static("fax")
    val job = static("funkció")
    val position = static("beosztás")
    val taxId = static("adóazonosító jel")
    val socialSecurityNumber = static("TAJ szám")
    val retiredJudge = static("nyugdíjas bíró")
    val mothersName = static("anyja neve")
    val birthName = static("születési név")
    val dateOfBirth = static("születési dátum")
    val placeOfBirth = static("születési hely")
    val address = static("cím")
    val postalAddress = static("levelezési cím")
    val transferAddress = static("utalási cím")
    val companyRegistrationNumber = static("cégjegyzékszám")
    val euTaxId = static("EU adószám")
    val note = static("megjegyzés")

    val document = static("Dokumentum")
    val documents = static("Dokumentumok")
    val addDocument = static("Dokumentum feltöltés")
    val editDocument = static("Edit Document")
    val removeDocument = static("Remove Document")

    val proceedingFeeCondition = static("ügy díj kondíció")
    val feeTableEntries = static("Fee Table Entries")
    val addFeeTableEntry = static("Add Fee Table Entry")
    val editFeeTableEntry = static("Edit Fee Table Entry")
    val removeFeeTableEntry = static("Remove Fee Table Entry")

    val folders = static("mappák")
    val addFolder = static("Add Folder")
    val editFolder = static("Edit Folder")
    val removeFolder = static("Remove Folder")

    val folderType = static("mappa típus")
    val folderTypes = static("mappa típusok")
    val addFolderType = static("új mappa típus")
    val editFolderType = static("mappa típus szerkesztése")
    val removeFolderType = static("mappa típus törlése")

    val documentType = static("dokumentum típus")
    val documentTypes = static("dokumentum típusok")
    val addDocumentType = static("új dokumentum típus")
    val editDocumentType = static("dokumentum típus szerkesztése")
    val removeDocumentType = static("dokumentum típus törlése")

    val hearing = static("tárgyalás")
    val hearings = static("Tárgyalások")
    val addHearing = static("Tárgyalás kitűzése")
    val editHearing = static("Tárgyalás szerkesztése")
    val removeHearing = static("Tárgyalás eltávolítása")

    val participation = static("résztvevő")
    val participants = static("Résztvevők")
    val addParticipant = static("Résztvevő hozzáadása")
    val editParticipation = static("Résztvevő szerkesztése")
    val removeParticipation = static("Remove Participation")

    val proceedingFee = static("ügy díj")
    val proceedingFees = static("Díjak")
    val addProceedingFee = static("Díj hozzáadása")
    val editProceedingFee = static("Edit ProceedingFee")
    val removeProceedingFee = static("Remove ProceedingFee")

    val proceedingHistories = static("ProceedingHistories")
    val addProceedingHistory = static("Add ProceedingHistory")
    val editProceedingHistory = static("Edit ProceedingHistory")
    val removeProceedingHistory = static("Remove ProceedingHistory")

    val proceedings = static("ügyek")
    val proceeding = static("ügy")
    val addProceeding = static("Ügy hozzáadása")
    val editProceeding = static("Edit Proceeding")
    val removeProceeding = static("Remove Proceeding")

    val space = static("Helyszín")
    val spaces = static("Helyszínek")
    val addSpace = static("Helyszín hozzáadása")
    val editSpace = static("Helyszín szerkesztése")
    val removeSpace = static("Helyszín törlése")

    val task = static("Feladat")
    val tasks = static("Feladatok")
    val addTask = static("Feladat hozzáadása")
    val editTask = static("Feladat szerkesztése")
    val viewTask = static("Feladat részletek")
    val deadline = static("Határidő")
    val assignedTo = static("felelős")

    val taskType = static("feladat típus")
    val taskTypes = static("Feladat típusok")
    val addTaskType = static("Feladat típus hozzáadása")
    val editTaskType = static("Feladat típus szerkesztése")

    val applicationTitle = static("VB Portál")
    val applicationTitleSupport = static("Állandó Választottbíróság - MKIK")
    val main = static("Főmenü")
    val archive = static("Archívum")
    val calendar = static("Naptár")
    val meetingRoom = static("Tárgyalók")
    val administration = static("Adminisztráció")
    val history = static("Történet")
    val todo = static("TODO")

    val caseNumber = static("Ügyszám")
    val documentTitleIsMandatory = static("A dokumentum címét meg kell adni.")

    val programmaticName = static("Belső név")
    val displayName = static("Megjelenített név")
    val contextName = static("Környezet")
    val displayOrder = static("Megjelenítési sorrend")

    val officialRegistrations = static("Hivatalos azonosítók")

    val loginFail = static("érvénytelen belépési adatok")
    val accountLocked = static("zárolt fiók")

    val accountName = static("fióknév")
    val password = static("jelszó")
    val contentTypes = static("fájl típusok")
    val contentType = static("fájl típus")

    val addContentType = static("fájl típus hozzáadása")
    val editContentType = static("fájl típus szerkesztése")

    val valasztottbirosagiDij = static("Választottbírósági díj")
    val regisztraciosDijSzukseges = static("Regisztrációs díj szükséges")
    val hianypotlasSzukseges = static("Hiánypótlás szükséges")
    val values = static("Értékek")

    val minimumFee = static("minimum")
    val maximumFee = static("maximum")
    val validFrom = static("érvényesség kezdete")
    val subjectValueLimit = static("sávhatár")
    val baseFee = static("alapdíj")
    val percent = static("százalék")
    val percentageBasis = static("százalék alap")

    val esemenyek = static("események")

    val subject = static("jogcím")

    val checking = static("ellenőrzés")
    val deadlineDate = static("határidő dátum")
    val deadlineTime = static("határidő időpont")

    val connections = static("Interfészek", "Kapcsolatok társrendszerekkel, u.m. levelező szerverek, adatbázisok.")

    val impressum = static("Impresszum", "Adatvédelmi szabályzat, felhasználási feltételek stb.")

    val system = static("rendszer")
    val startup = static("indítás")
    val shutdown = static("leállítás")
    val shutdownLocked = static("távoli rendszer leállítás letiltva")
    val invalidShutdownRequest = static("érvénytelen rendszer leállítási kérés")

    val onlyAdminMayChange = static("csak rendszergazda által változtatható")

    val FeladatKesz = static("feladat kész")
    val FeladatKeszVissza = static("feladat kész visszavonása")
    val DokumentumLetoltes = static("dokumentum letöltés")

    val refresh = static("frissítés")
    val refreshSuccess = static("sikeres frissítés")
    val ResztvevoFrissites = static("résztvevő adat frissítés")
    val ResztvevoMeghivas = static("résztvevő meghívás")
    val topic = static("témakör")
    val verb = static("művelet")
    val events = static("események")
    val modify = static("módosítás")

    val invalidCaseNumber = static("hibás ügyszám (helyes: VB/ÉÉxxx)")
    val duplicateCaseNumber = static("foglalt ügyszám")

    val refreshBeforeInvite =
        static("Szükséges az e-mail cím megadása! Fel kell venni a Partner alapértelmezett email címét a DMS One-ban, és frissíteni (Szerkesztés/Frissítés) a meghívás előtt.")

    val dmsOneMissingEmail =
        static("A DMSOne-ból törölve lett az alapértelmezett e-mail cím! Fel kell venni a Partner alapértelmezett email címét a DMS One-ban, és frissíteni (Szerkesztés/Frissítés) a meghívás előtt.")

    val dmsOneYetAnotherMissingEmail =
        static("A DMSOne-ból törölve lett az alapértelmezett e-mail cím! Fel kell venni a Partner alapértelmezett email címét a DMS One-ban a frissítés előtt.")

    val accountType = static("szerepkör")

    val translations = static("fordítások")
    val addTranslation = static("fordítás hozzáadása")
    val editTranslation = static("fordítás szerkesztése")

    val primaryClaimantName = static("elsődleges felperes")
    val primaryRespondentName = static("elsődleges alperes")
    val secretaryName = static("ügyintéző")

    val manualFiling = static("kézzel iktatva")
    val filingInProgress = static("iktatás folyamatban")

    val test = static("TESZT")
    val search = static("keresés")
    val uploadInProgress = static("Dokumentum feltöltés van még folyamatban. Ha elhagyja az oldalt, megszakad a feltöltés. Biztosan elhagyja az oldalt?")
    val documentUploadReady = static("Befejezett dokumentum feltöltés!")

    val privateType = static("privát típus")
    val attachment = static("melléklet")

    val noEmail = static("Nincs megadva email cím! Kérem állítsa be a Partner alapértelmezett email címét a DMS One-ban és Frissítse az adatokat!")
    val expiredActivationKey = static("Ez az aktiváló kulcs már nem érvényes!")
    val mpaidMandatory = static("Ki kell választani a résztvevőt, hogy ki legyen töltve az MPAID mező!")
    val nameDifference = static("A név megváltozott!")
    val emailDifference = static("Az e-mail cím megváltozott!")
    val addressDifference = static("A cím megváltozott!")

    val passwordResetInitLabel = static("Fiók név vagy e-mail cím")
}