package hu.mkik.vb.portal.model

import hu.simplexion.z2.auth.model.Role
import hu.simplexion.z2.schematic.Schematic

class ProceedingSettings : Schematic<ProceedingSettings>() {
    var type by enum<ProceedingType>()
    var claimantRole by uuid<Role>()
    var claimantRepresenterRole by uuid<Role>()
    var claimantCaseManagerRole by uuid<Role>()
    var respondentRole by uuid<Role>()
    var respondentRepresenterRole by uuid<Role>()
    var respondentCaseManagerRole by uuid<Role>()
    var intervenerRole by uuid<Role>()
    var intervenerRepresenterRole by uuid<Role>()
    var chairmanRole by uuid<Role>()
    var claimantArbitratorRole by uuid<Role>()
    var respondentArbitratorRole by uuid<Role>()
    var secretaryRole by uuid<Role>()
    var councilRole by uuid<Role>()
}