package hu.mkik.vb.portal.api.finance

import hu.mkik.vb.portal.model.Proceeding
import hu.mkik.vb.portal.model.finance.*
import hu.simplexion.z2.services.Service
import hu.simplexion.z2.util.UUID

interface BankApi : Service {

    suspend fun statements() : List<BankStatement>

    suspend fun loadStatement(source: ByteArray): String

    suspend fun pendingTransactions() : List<BankTransaction>

    suspend fun closedTransactions() : List<BankTransaction>

    suspend fun transactionBundle(uuid : UUID<BankTransaction>) : BankTransactionBundle

    suspend fun allocate(
        transaction : UUID<BankTransaction>,
        proceeding : UUID<Proceeding>,
        amount : Long,
        incomingRequirement: UUID<IncomingRequirement>? = null,
        outgoingRequirement: UUID<OutgoingRequirement>? = null
    )

}