package hu.mkik.vb.portal.ui.proceeding.finance.logic

import hu.mkik.vb.portal.model.FeeType
import hu.mkik.vb.portal.model.Participation
import hu.mkik.vb.portal.model.Proceeding
import hu.mkik.vb.portal.model.ProceedingSettings
import hu.mkik.vb.portal.model.finance.OutgoingRequirement
import hu.mkik.vb.portal.model.finance.OutgoingRequirementType
import hu.mkik.vb.portal.model.finance.ProceedingFeeCalculation
import hu.mkik.vb.portal.ui.proceeding.arbitratorRoles
import hu.mkik.vb.portal.ui.proceeding.feeTable
import hu.simplexion.z2.util.hereAndNow
import kotlinx.datetime.LocalDate
import kotlin.collections.filter
import kotlin.math.max
import kotlin.math.min

class ProceedingFeeCalculator(
    val proceeding: Proceeding,
    val settings: ProceedingSettings,
    val participations: List<Participation>,
    val counter: Boolean,
    val numberOfArbitrators: Int? = null
) {

    var amount = if (counter) proceeding.counterClaimValue else proceeding.claimValue

    val errors = mutableListOf<String>()
    val result = ProceedingFeeCalculation()

    val arbitratorRoles = settings.arbitratorRoles()
    val arbitrators = participations.filter { it.proceedingRole in arbitratorRoles && it.active }

    init {
        result.registrationFee = registrationFee().ifOk
        result.administrationFee = administrationFee().ifOk
        result.arbitratorHonorarium = arbitratorHonorarium().ifOk
        result.arbitratorsHonorarium = arbitratorsHonorarium().ifOk
        result.chairmanHonorarium = chairmanHonorarium().ifOk
        result.reserveFund = reserveFund().ifOk
        result.levy = levy().ifOk
        result.socialSecurityContributionRequirements = if (numberOfArbitrators == 0) emptyList() else socialSecurityContributionRequirements()
        result.socialSecurityContribution = result.socialSecurityContributionRequirements.sumOf { it.requiredAmount }.ifOk

        result.total = (
            result.administrationFee +
                result.arbitratorsHonorarium +
                result.chairmanHonorarium +
                result.levy +
                result.reserveFund +
                result.socialSecurityContribution
            )

        result.missingFees = errors.isNotEmpty()
    }

    val Long.ifOk
        get() = if (numberOfArbitrators == 0) 0L else this

    fun findFee(feeType: FeeType, date: LocalDate) =
        feeTable
            .filter { it.proceedingType == proceeding.type && it.type == feeType }
            .filter { it.validFrom <= date }
            .sortedBy { it.subjectValueLimit }
            .sortedBy { it.validFrom }
            .lastOrNull { it.subjectValueLimit <= amount }

    private fun registrationFee(): Long {
        if (counter && proceeding.waiveCounterRegistrationFee) return 0

        val fee = findFee(FeeType.RegistrationFee, proceeding.letterOfClaimantDate)

        if (fee == null) {
            errors += "registration fee is null"
            return 0
        }

        return fee.baseFee
    }

    private fun administrationFee(): Long {
        val fee = findFee(FeeType.AdministrationFee, proceeding.letterOfClaimantDate)

        if (fee == null) {
            errors += "administration fee is null"
            return 0
        }

        return max(fee.minimumFee, fee.baseFee + (amount - fee.subjectValueLimit).percentage(fee.percent))
    }

    private fun arbitratorHonorarium(): Long {
        val fee = findFee(FeeType.ArbitratorsHonorarium, proceeding.letterOfClaimantDate)

        if (fee == null) {
            errors += "arbitrator honorarium is null"
            return 0
        }

        return max(fee.minimumFee, fee.baseFee + max(0, amount - fee.subjectValueLimit).percentage(fee.percent))
    }

    private fun arbitratorsHonorarium(): Long {
        if (numberOfArbitrators != null) {
            return (numberOfArbitrators - 1) * result.arbitratorHonorarium // -1 = chairman
        }

        if (arbitrators.size <= 1) return 0

        return (arbitrators.size - 1) * result.arbitratorHonorarium // -1 = chairman
    }

    private fun chairmanHonorarium(): Long {
        val fee = findFee(FeeType.ChairmanHonorarium, proceeding.letterOfClaimantDate)

        if (fee == null) {
            errors += "chairman honorarium is null"
            return 0
        }

        return result.arbitratorHonorarium.percentage(fee.percent)
    }

    private fun reserveFund(): Long {
        val fee = findFee(FeeType.ReserveFund, proceeding.letterOfClaimantDate)

        if (fee == null) {
            errors += "reserve fund is null"
            return 0
        }

        return (result.chairmanHonorarium + result.arbitratorsHonorarium).percentage(fee.percent)
    }

    fun levy(): Long {
        val skip = if (counter) proceeding.isRespondentLevyFree else proceeding.isClaimantLevyFree
        if (skip) return 0

        val fee = findFee(FeeType.Levy, proceeding.closeDate ?: hereAndNow().date)

        if (fee == null) {
            errors += "levy is null"
            return 0
        }

        return min(fee.maximumFee, max(fee.minimumFee, amount.percentage(fee.percent)))
    }

    fun socialSecurityContributionRequirements(): List<OutgoingRequirement> {

        val fee = findFee(FeeType.SocialSecurityContribution, proceeding.closeDate ?: hereAndNow().date)

        if (fee == null) {
            errors += "socialSecurityContribution is null"
            return emptyList()
        }

        if (numberOfArbitrators != null) {
            return listOf(
                OutgoingRequirement().also { req ->
                    req.proceeding = proceeding.uuid
                    req.type = OutgoingRequirementType.SocialSecurityContribution
                    req.requiredAmount = (result.chairmanHonorarium + result.arbitratorsHonorarium).percentage(fee.percent)
                    req.counter = counter
                }
            )
        }

        return arbitrators.map { arbitrator ->
            val base = if (arbitrator.proceedingRole == settings.chairmanRole) {
                result.chairmanHonorarium
            } else {
                result.arbitratorHonorarium
            }
            OutgoingRequirement().also { req ->
                req.proceeding = proceeding.uuid
                req.participation = arbitrator.uuid
                req.type = OutgoingRequirementType.SocialSecurityContribution
                req.requiredAmount = if (arbitrator.retired) {
                    0
                } else {
                    max(0, base).percentage(fee.percent)
                }
                req.counter = counter
            }
        }
    }

}