package hu.mkik.vb.portal.ui.proceeding.participation.modals

import hu.mkik.vb.portal.model.Participation
import hu.mkik.vb.portal.model.RoleCandidate
import hu.mkik.vb.portal.ui.accountVBService
import hu.mkik.vb.portal.ui.participationService
import hu.mkik.vb.portal.ui.proceeding.*
import hu.mkik.vb.portal.ui.proceeding.participation.components.*
import hu.mkik.vb.portal.ui.proceeding.participation.util.rankCollision
import hu.mkik.vb.portal.ui.strings
import hu.simplexion.z2.adaptive.modal.modal
import hu.simplexion.z2.browser.css.*
import hu.simplexion.z2.browser.html.columnGap
import hu.simplexion.z2.browser.html.div
import hu.simplexion.z2.browser.html.grid
import hu.simplexion.z2.browser.immaterial.schematic.field
import hu.simplexion.z2.browser.immaterial.schematic.radioField
import hu.simplexion.z2.browser.material.button.textLaunchButton
import hu.simplexion.z2.browser.material.px
import hu.simplexion.z2.browser.material.snackbar.snackbar
import hu.simplexion.z2.browser.material.snackbar.warningSnackbar
import hu.simplexion.z2.util.localLaunch

fun editParticipation(participation: Participation) =
    modal {
        val roles = currentProceeding.roles
        val participations = currentParticipations
        val copy = participation.copy()

        val roleCandidate = RoleCandidate().apply {
            role = roles.first { it.uuid == copy.proceedingRole }
        }

        title("${strings.editParticipation.localized} - $currentCaseNumber")

        body {
            grid(
                "max-content minmax(800px,1fr)", "minmax(0,1fr) minmax(0,1fr)",
                heightMinContent, gridGap16
            ) {

                div(borderOutline, borderRadius4, pr24) {
                    radioField { roleCandidate.role } options { roles.sortedBy { it.transformedName } } itemBuilder { +it.transformedName }
                } gridRow "1/span2"

                grid("1fr 1fr", "repeat(5, min-content)") {
                    attach(copy)

                    columnGap = 16.px

                    textLaunchButton(strings.refresh) { refresh(copy) }
                    field { copy.active } css alignSelfCenter css mb8
                    field { copy.fullName }.also { it.gridColumn("1/3") } readOnly true
                    field { copy.email } readOnly true
                    field { copy.mpaid } readOnly true
                    field { copy.address }.also { it.gridColumn("1/3") } readOnly true
                    field { copy.phone } readOnly true
                    field { copy.bankAccount } readOnly true
                    field { copy.rank }.also { it.supportEnabled = false }
                    retired(roleCandidate, copy) css alignSelfCenter
                }

                representation(roleCandidate, copy) gridRow "2" gridColumn "2"
            }
        }

        save {
            if (!copy.isValidForCreate || !roleCandidate.isValidForCreate) {
                snackbar(strings.invalidFields)
                return@save
            }

            copy.proceedingRole = roleCandidate.role.uuid

            if (!isRoleValidForSoleArbitrator(copy)) {
                warningSnackbar(strings.soleArbitratorInvalidRole)
                return@save
            }

            if (rankCollision(participations, copy)) return@save

            val account = accountVBService.getUuidByMpaid(copy.mpaid)
            if (account != null && copy.email.isEmpty()) {
                warningSnackbar(strings.noEmail)
                return@save
            }

            participationService.update(copy)

            val original = participationService.representations(copy.uuid).toMutableList()

            original.forEach {
                if (representationSelects[it] != true) {
                    participationService.removeRepresentation(copy.uuid, it)
                }
            }

            representationSelects.forEach {
                if (it.key !in original) {
                    participationService.addRepresentation(copy.uuid, it.key)
                }
            }

            refreshParticipations()
            refreshEvents()

            close()
        }
    }

private suspend fun refresh(participation: Participation) {
    modal {
        title = strings.inProgress
        localLaunch {
            val current = participationService.readDMSOne(participation.mpaid)

            if (current.email.isEmpty()) {
                warningSnackbar(strings.dmsOneYetAnotherMissingEmail)
                close()
                return@localLaunch
            }

            participationService.refresh(participation.mpaid)
            refreshParticipations()
            participation.copyFrom(currentParticipations.first { it.mpaid == participation.mpaid })
            snackbar(strings.refreshSuccess)
            close()
        }
    }
}
