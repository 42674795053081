package hu.mkik.vb.portal.ui.proceeding.finance.logic

/**
 * Calculates percentage with [percent] being scaled by 3:
 *
 * 1% = 1000
 */
fun Long.percentage(percent: Long): Long {
    val value = this * percent / 100
    val result = if (value % 1000 >= 500) {
        value / 1000 + 1
    } else {
        value / 1000
    }

    return result
}