package hu.mkik.vb.portal.ui.proceeding.edit.pages

import hu.mkik.vb.portal.model.Proceeding
import hu.mkik.vb.portal.ui.component.locale
import hu.mkik.vb.portal.ui.component.title
import hu.mkik.vb.portal.ui.proceeding.currentProceeding
import hu.mkik.vb.portal.ui.proceeding.firstOrNull
import hu.mkik.vb.portal.ui.proceeding.gridConfig
import hu.mkik.vb.portal.ui.proceeding.validateCaseNumber
import hu.mkik.vb.portal.ui.proceedingService
import hu.mkik.vb.portal.ui.strings
import hu.mkik.vb.portal.ui.util.section
import hu.simplexion.z2.browser.css.*
import hu.simplexion.z2.browser.html.*
import hu.simplexion.z2.browser.immaterial.schematic.BoundField
import hu.simplexion.z2.browser.immaterial.schematic.attach
import hu.simplexion.z2.browser.immaterial.schematic.attachListener
import hu.simplexion.z2.browser.immaterial.schematic.field
import hu.simplexion.z2.browser.layout.surfaceContainerLowest
import hu.simplexion.z2.browser.material.button.filledLaunchButton
import hu.simplexion.z2.browser.material.button.textButton
import hu.simplexion.z2.browser.material.px
import hu.simplexion.z2.browser.material.snackbar.snackbar
import hu.simplexion.z2.browser.material.snackbar.warningSnackbar
import hu.simplexion.z2.localization.localized
import mainRouter

fun Z2.editProceeding() =
    surfaceContainerLowest(borderOutline) {
        title(currentProceeding.proceeding.caseNumber, strings.edit)

        grid("1fr 1fr", "min-content 1fr min-content min-content", pl8, pr8) {
            columnGap = 32.px
            rowGap = 16.px

            val copy = currentProceeding.proceeding.copy()
            lateinit var setoffObjectionField: BoundField<*>
            lateinit var caseNumberField: BoundField<*>


            section(strings.description) {
                style.maxWidth = 800.px
                grid("1fr min-content", "min-content", 16, pb20, pt4) {
                    div { + "${strings.proceedingType.localized}:" }
                    div(whiteSpaceNoWrap) { + copy.type.localized }
                }

                caseNumberField = field { copy.caseNumber }.validateCaseNumber(copy)
                field { copy.subject }
                field { copy.description }

                grid("1fr 1fr", "min-content", 24, alignItemsCenter) {
                    field { copy.soleArbitrator }.also { it.supportEnabled = false } label strings.soleArbitratorProceeding
                    locale({ }) { copy.language }
                }
            }

            section(strings.finance) {
                grid("1fr", "repeat(4,min-content)", gridGap24) {
                    grid("repeat(auto-fit, minmax(300px, 1fr))", "min-content", gridGap24) {
                        field { copy.claimValue } readOnly true supportText strings.modifyOnIncomingPage
                        field { copy.counterClaimValue } readOnly true supportText strings.modifyOnIncomingPage
                    }

                    grid("repeat(auto-fit, minmax(300px, 1fr))", "min-content", gridGap24) {

                        div { }

                        setoffObjectionField = field { copy.setoffObjection }
                        attachListener(copy) {
                            when {
                                copy.setoffObjection > copy.claimValue -> {
                                    setoffObjectionField.error = true
                                    setoffObjectionField.errorText = strings.setoffGreaterThanClaim.localized
                                }

                                copy.setoffObjection > copy.counterClaimValue -> {
                                    setoffObjectionField.error = true
                                    setoffObjectionField.errorText = strings.setoffGreaterThanCounterClaim.localized
                                }

                                else -> setoffObjectionField.error = false
                            }
                        }
                    }

                    grid("repeat(auto-fit, minmax(300px, 1fr))", "min-content", gridGap24) {
                        field { copy.expedited }
                        field { copy.waiveCounterRegistrationFee }
                    }

                    grid("repeat(auto-fit, minmax(300px, 1fr))", "min-content", gridGap24) {
                        field { copy.isClaimantLevyFree }
                        field { copy.isRespondentLevyFree }
                    }
                }
            }

            section(strings.dates) {
                grid("repeat(auto-fit, minmax(292px, 1fr))", "min-content", gap = 16) {
                    field { copy.letterOfClaimantDate }
                    field { copy.contractDate }
                    field { copy.closeDate }
                    field { copy.accountingDate }
                }
            }.also { it.gridColumn = "1/3 " }

            section(strings.status) {
                grid("1fr", "repeat(4,min-content)", gap = 16) {
                    field { copy.established }
                    field { copy.partialJudgement }
                    field { copy.adjourned }
                    field { copy.closed }
                    field { copy.reopened }
                    field { copy.archived }
                }
            }

            section(strings.closeType) {
                div(pl24) {
                    field { copy.closeType } css bodyMedium
                    div().attach(copy) {
                        if (copy.closeType != null) {
                            textButton(strings.removeChosenCloseType) { copy.closeType = null }
                        }
                    }
                }
            }

            div(wFull, borderBottomOutlineVariant, pt24, mb16) {
                gridColumn = "1/3"
            }

            grid {
                gridConfig("1fr 1fr", "min-content min-content", "1/3")

                textButton(strings.back) { mainRouter.back() } css justifySelfStart

                filledLaunchButton(strings.save) {
                    if (caseNumberField.error || setoffObjectionField.error) {
                        warningSnackbar(strings.invalidFields)
                        return@filledLaunchButton
                    }
                    if (soleArbitratorInvalid(copy)) {
                        warningSnackbar(strings.soleArbitratorInvalid)
                        return@filledLaunchButton
                    }

                    proceedingService.saveEditable(copy)
                    currentProceeding.proceeding = proceedingService.get(copy.uuid)
                    snackbar(strings.saveSuccess)
                } css justifySelfEnd
            }
        }
    }

private fun soleArbitratorInvalid(copy: Proceeding): Boolean {
    if (!copy.soleArbitrator) return false

    val settings = currentProceeding.settings

    if (settings.claimantArbitratorRole.firstOrNull != null) return true
    if (settings.respondentArbitratorRole.firstOrNull != null) return true

    return false
}