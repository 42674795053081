package hu.mkik.vb.portal.ui.bank.modals

import hu.mkik.vb.portal.ui.bankService
import hu.mkik.vb.portal.ui.strings
import hu.simplexion.z2.browser.browserIcons
import hu.simplexion.z2.browser.browserStrings
import hu.simplexion.z2.browser.css.*
import hu.simplexion.z2.browser.html.*
import hu.simplexion.z2.browser.immaterial.file.FileSelect
import hu.simplexion.z2.browser.immaterial.file.fileSelect
import hu.simplexion.z2.browser.material.button.textButton
import hu.simplexion.z2.browser.material.icon.icon
import hu.simplexion.z2.browser.material.modal.modal
import hu.simplexion.z2.browser.material.snackbar.errorSnackbar
import hu.simplexion.z2.browser.material.snackbar.snackbar
import hu.simplexion.z2.browser.material.snackbar.warningSnackbar
import hu.simplexion.z2.browser.material.stateLayer
import hu.simplexion.z2.localization.locales.localized
import hu.simplexion.z2.localization.runtime.localized
import hu.simplexion.z2.localization.text.LocalizedText
import hu.simplexion.z2.util.formatByteLength
import hu.simplexion.z2.util.here
import kotlinx.coroutines.delay
import kotlinx.datetime.Instant
import org.khronos.webgl.ArrayBuffer
import org.khronos.webgl.Int8Array
import org.w3c.files.File
import org.w3c.files.FileReader

fun Z2.uploadStatement(
    onUpload: () -> Unit
) =
    modal(w400) {

        title(strings.upload)

        lateinit var container : Z2
        lateinit var select : FileSelect

        var selectedFile : File? = null

        fun update() {
            with(container) {
                clear()
                if (selectedFile == null) {
                    textButton(strings.dropFileHere, false) {  } css justifySelfCenter
                } else {
                    fileListEntry(selectedFile!!) {
                        selectedFile = null
                        select.inputElement.value = ""
                        update()
                    }
                }
            }
        }

        fun selected(files: List<File>) {
            selectedFile = files.firstOrNull()
            update()
        }

        body {
            select = fileSelect(fileBrowserOnClick = true, fileSelectedFun = { selected(it) }, multiple = false) {
                container = this
                update()
            }
        }

        save(strings.upload) {
            val file = selectedFile
            if (file == null) {
                warningSnackbar(strings.noFilesSelected)
                return@save
            }

            val problems = problems(file)
            if (problems.isNotEmpty()) {
                warningSnackbar(problems.joinToString().localized)
                return@save
            }

            upload(file, onUpload)
            close()
        }
    }

fun Z2.fileListEntry(file: File, remove: (file: File) -> Unit) {
    grid(gridGap8, positionRelative, pt8, pb8, pl12, pr8) {
        stateLayer().addCss(borderRadius4)

        gridTemplateColumns = "1fr 5em 32px"

        div {
            div(mb4) { + file.name }
            div(bodySmall, onSurfaceVariantText, displayFlex, flexDirectionRow) {
                // TODO remove the lastmodified magic
                val lastModified = (js("file.lastModified.toString()") as String).toLongOrNull()
                lastModified?.let { + Instant.fromEpochMilliseconds(lastModified.toLong()).here().localized }

                problems(file).let {
                    if (it.isNotEmpty()) div(pl16, errorText) { + it.joinToString() }
                }
            }
        }

        div(justifySelfEnd, alignSelfCenter, bodySmall, onSurfaceVariantText) {
            + file.size.formatByteLength(2, browserStrings.zeroBytes, browserStrings.bytes)
        }

        div(alignSelfCenter, pl8) {
            icon(browserIcons.cancel, cssClass = errorText).onClick {
                it.stopPropagation()
                remove(file)
            }
        }
    }
}

fun problems(file: File): MutableList<LocalizedText> {
    val problems = mutableListOf<LocalizedText>()
    if (! extensionOk(file)) problems += strings.invalidExtension
    if (! sizeOk(file)) problems += strings.sizeOverLimit
    return problems
}

fun extensionOk(file: File): Boolean {
    return file.name.substringAfterLast('.').lowercase() == "stm"
}

fun sizeOk(file: File): Boolean {
    return file.size.toLong() <= 1_000_000
}

suspend fun upload(file: File, onUpload: () -> Unit) {
    val reader = FileReader()
    reader.readAsArrayBuffer(file)
    while (reader.readyState != FileReader.DONE) {
        delay(50)
    }
    val result = bankService.loadStatement(Int8Array(reader.result as ArrayBuffer).unsafeCast<ByteArray>())
    if (result.isEmpty()) {
        snackbar(strings.successfulUpload)
        onUpload()
    } else {
        errorSnackbar(result.localized)
    }
}