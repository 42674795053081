package hu.mkik.vb.portal.ui.proceeding.participation.modals

import hu.mkik.vb.portal.model.Participation
import hu.mkik.vb.portal.model.RoleCandidate
import hu.mkik.vb.portal.ui.accountVBService
import hu.mkik.vb.portal.ui.participationService
import hu.mkik.vb.portal.ui.proceeding.currentParticipations
import hu.mkik.vb.portal.ui.proceeding.currentProceeding
import hu.mkik.vb.portal.ui.proceeding.participation.components.*
import hu.mkik.vb.portal.ui.proceeding.participation.util.duplicatedPartner
import hu.mkik.vb.portal.ui.proceeding.participation.util.rankCollision
import hu.mkik.vb.portal.ui.proceeding.refreshEvents
import hu.mkik.vb.portal.ui.proceeding.refreshParticipations
import hu.mkik.vb.portal.ui.strings
import hu.simplexion.z2.adaptive.modal.modal
import hu.simplexion.z2.browser.css.*
import hu.simplexion.z2.browser.html.div
import hu.simplexion.z2.browser.html.grid
import hu.simplexion.z2.browser.immaterial.schematic.field
import hu.simplexion.z2.browser.immaterial.schematic.radioField
import hu.simplexion.z2.browser.material.snackbar.snackbar
import hu.simplexion.z2.browser.material.snackbar.warningSnackbar

fun addParticipation() =
    modal {

        lateinit var editor: ParticipantSelector
        val roleCandidate = RoleCandidate()
        val copy = Participation()

        val proceeding = currentProceeding.proceeding
        val roles = currentProceeding.roles
        val participations = currentParticipations

        title(strings.addParticipant.localized)

        body {
            grid("max-content minmax(800px, 1fr)", "minmax(0,1fr) minmax(0,1fr)", gridGap16, heightMinContent) {

                div(borderOutline, borderRadius4, pr24) {
                    radioField { roleCandidate.role } options { roles.sortedBy { it.transformedName } } itemBuilder { +it.transformedName }
                } gridRow "1/span2"

                div {
                    editor = ParticipantSelector(this, add = false).main()
                    grid("1fr 1fr", "min-content", gridGap16) {
                        field { copy.rank }.also { it.supportEnabled = false }
                        retired(roleCandidate, copy) css alignSelfCenter
                    }
                } gridRow "1" gridColumn "2"

                representation(roleCandidate, copy) gridRow "2" gridColumn "2"
            }
        }

        save {
            if (editor.candidate.mpaid == 0) {
                warningSnackbar(strings.mpaidMandatory)
                return@save
            }

            if (!editor.candidate.isValidForCreate || !roleCandidate.isValidForCreate) {
                snackbar(strings.invalidFields)
                return@save
            }

            copy.proceedingRole = roleCandidate.role.uuid

            val settings = currentProceeding.settings

            if (currentProceeding.proceeding.soleArbitrator) {
                if (copy.proceedingRole == settings.claimantArbitratorRole || copy.proceedingRole == settings.respondentArbitratorRole) {
                    warningSnackbar(strings.soleArbitratorInvalidRole)
                    return@save
                }
            }

            val uuid = Participation().let {
                it.proceeding = proceeding.uuid
                it.mpaid = editor.candidate.dmsonePartner.mpaid
                it.participant = editor.candidate.account
                it.fullName = editor.candidate.fullName
                it.address = editor.candidate.address
                it.email = editor.candidate.email
                it.bankAccount = editor.candidate.bankAccount
                it.phone = editor.candidate.phone
                it.retired = copy.retired
                it.proceedingRole = copy.proceedingRole
                it.rank = copy.rank

                if (rankCollision(participations, it)) return@save
                if (duplicatedPartner(participations, it)) return@save

                val account = accountVBService.getUuidByMpaid(it.mpaid)
                if (account != null && it.email.isEmpty()) {
                    warningSnackbar(strings.noEmail)
                    return@save
                }

                participationService.add(it)
            }

            representationSelects.forEach {
                participationService.addRepresentation(uuid, it.key)
            }

            refreshParticipations()
            refreshEvents()

            close()
        }
    }